import { ChakraProvider } from '@chakra-ui/react'
import { DefaultSeo } from 'next-seo'
import theme from 'src/theme/theme'
import MainLayout from 'src/layout/MainLayout'
import SEO from 'src/seo/next-seo.config.js'
import Head from 'next/head'
import UserProvider from 'src/user/UserContext'
import NextNprogress from 'nextjs-progressbar'
import Error from 'next/error'
import * as Sentry from '@sentry/node'
import SWRProvider from 'src/common/SWRProvider'
import firebase from 'src/util/firebaseWeb'
import { Router } from 'next/router'
import { useEffect } from 'react'
import { windowContext } from 'src/util'
import CookieConsent from 'src/common/CookieConsent'
import { Global } from '@emotion/react'
import { AlertBannerProvider } from 'src/alertBanner'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.VERCEL_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  })
}

/**
 * the faintest ink is better
 * than the best memory
 */
function App({ Component, pageProps, err }) {
  if (pageProps.error) {
    return <Error statusCode={pageProps.error.statusCode} title={pageProps.error.message} />;
  }

  useEffect(() => {
    if (windowContext()) {
      firebase.analytics()
      Router.events.on('routeChangeComplete', url => firebase.analytics().setCurrentScreen(url))
    }
  }, [])

  return (
    <>
      <Head>
        <meta
          name="msapplication-TileColor"
          content="#00aba9"
        />
        <meta
          name="theme-color"
          content="#ffffff"
        />
        <meta
          content="width=device-width, initial-scale=1"
          name="viewport"
        />
      </Head>
      <ChakraProvider theme={theme}>
        <Global
          styles={`
          @font-face {
            font-family: 'GTWalsheim';
            src: url(/fonts/GTWalsheimMedium.otf);
            font-style: medium;
            font-weight: 500;
            font-display: swap;
          }
          @font-face {
            font-family: 'Inter';
            src: url(/fonts/inter-var-latin.woff2) format('woff2');
            font-weight: 400 800;
            font-style: oblique 0deg 20deg;
            font-display: swap;
          }
        `}
        />
        {/* return user from getServerSideProps to have it available here. static pages will not have a user here */}
        <UserProvider user={pageProps.user}>
          <SWRProvider>
            <AlertBannerProvider>
              <DefaultSeo {...SEO} />
              <NextNprogress color='#0070F3' />
              <MainLayout>
                {/* workaround for https://github.com/vercel/next.js/issues/8592 */}
                <Component {...pageProps} err={err} />
                <CookieConsent />
              </MainLayout>
            </AlertBannerProvider>
          </SWRProvider>
        </UserProvider>
      </ChakraProvider>
    </>
  )
}

export default App
