const windowContext: () => boolean = () => typeof window !== 'undefined'
const compose = (...fns: any[]) => (args?: any) => fns.reduceRight((arg, fn) => fn(arg), args)
const capitalize = (word: string) => word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : ''
const capitalizeEach = (phrase: string) =>
	phrase
		.split(' ')
		.map(capitalize)
		.join(' ');

const uid = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

/**
 * shallow compare two arrays of strings
 */
const arrDeepEqual = (arr1: string[], arr2: string[]) => {
	if (!arr1 || !arr2) return false;
	if (arr1.length !== arr2.length) return false;
	return arr1.every((_e, i) => arr1[i] === arr2[i])
}

/**
 * delete falsy properties from an object
 * returns an object of same or fewer properties
 */
const deleteFalsyProperties = (o: object) => {
	return Object.fromEntries(Object.entries(o).filter(([x, y]) => y))
}

/**
 * just a lodash debounce
 */
function debounce(func, wait, immediate = false) {
	let timeout;
	return function () {
		// @ts-ignore
		let context = this
		let args = arguments
		let later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

/**
 * combine two unique IDs into one
 * useful for creating an ID that's
 * unique to a combination, like
 * user/domain
 */
function uniqueByTwo(one: string, two: string) {
	return `${one.slice(0, 10)}${two.slice(0, 10)}`
}

function removeFalsy<T extends {}>(object): T {
	const v = Object.fromEntries(Object.entries(object).filter(([_, v]) => v))
	return v as T
}

export {
	uid,
	windowContext,
	compose,
	capitalize,
	capitalizeEach,
	removeFalsy,
	uniqueByTwo,
	arrDeepEqual,
	debounce,
	deleteFalsyProperties,
}
