import { Settings } from 'projectlink-common'
import { _fetch } from "src/util/access"
import endpoints from "./endpoints"

const create = (userId: string, data: Partial<Settings>) =>
  _fetch(`${endpoints.users(userId)}/settings`, {
    method: 'post',
    body: JSON.stringify(data),
  })

const update = (userId: string, data: Partial<Settings>) =>
  _fetch(`${endpoints.users(userId)}/settings`, {
    method: 'put',
    body: JSON.stringify(data),
  })

const settings = {
  create,
  update,
}

export default settings
