import { BoxProps } from "@chakra-ui/react"
import { FC } from "react"
import { compose } from "../util"

/**
 * hoc that adds inactive prop to typography components
 */
const withInactive = (Cmp: FC<BoxProps>) =>
  ({ inactive, ...rest }: BoxProps & { inactive?: boolean }) =>
    <Cmp
      {...rest}
      color={inactive ? 'primary.accent4' : rest.color}
    />

const withCustomProps = compose(
  withInactive,
)

export {
  withInactive,
  withCustomProps,
}