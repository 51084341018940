const endpoints = {
  accounts: (id = '') => `/api/accounts/${id}`,
  activity: (id = '') => `/api/activity/${id}`,
  admins: (id = '') => `/api/admins/${id}`,
  caseStudies: (id = '') => `/api/case-studies/${id}`,
  emails: () => `/api/emails`,
  feedback: () => `api/feedback`,
  help: () => `api/help`,
  messages: (id = '') => `/api/messages/${id}`,
  notifications: (id) => `/api/notifications/${id}`,
  organizations: (id = '') => `/api/organizations/${id}`,
  projects: (id = '') => `/api/projects/${id}`,
  references: (id = '') => `/api/references/${id}`,
  roles: (id = '') => `/api/roles/${id}`,
  stripe: () => `/api/stripe/`,
  signin: () => `/api/signin`,
  snippets: (id = '') => `/api/snippets/${id}`,
  tokens: () => `/api/tokens`,
  topics: (id = '') => `/api/topics/${id}`,
  transactions: (id = '') => `/api/transactions/${id}`,
  users: (id = '') => `/api/users/${id}`,
}

export default endpoints
