import dynamic from 'next/dynamic'
import useWindowSize from 'src/hooks/useWindowSize'

const DesktopMenu = dynamic(() => import('./DesktopMenu'))
const MobileMenu = dynamic(() => import('./MobileMenu'))

function Nav() {
  const { width } = useWindowSize()
  const isMobile = (width || 0) < 860

  return (
    <>
      {isMobile ?
        <MobileMenu /> :
        <DesktopMenu />
      }
    </>
  )
}

export default Nav
