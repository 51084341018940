import * as Sentry from '@sentry/browser'

/**
 * @param error - Error object
 * @param tags - tags to query errors by, ex: 'login'
 */
async function logError(err, tags = {}) {
  console.warn('logger!', err)
  Sentry.captureException(err)
}

export default logError
