import { forwardRef } from 'react'
import Link from 'next/link';
import { Box, Flex, Text, useColorMode } from '@chakra-ui/react';
import Nav from '../common/Nav'
import AppContainer from '../common/AppContainer';
import DarkModeSVG from 'src/svg/LogoDark'
import LightModeSVG from 'src/svg/LogoLight'
import { useAccent2, useAccent4 } from 'src/modeAwareColors';
import { routes } from 'src/navigation/util';
import useIsSignedIn from 'src/hooks/useIsSignedIn';

function Logo({ height = '24px' }) {
  const isSignedIn = useIsSignedIn()
  return (
    <Link href={isSignedIn ? routes.dashboard : routes.home} passHref>
      <LogoWithRef height={height} />
    </Link>
  );
}

const LogoWithRef = forwardRef<any, any>((props, ref) => {
  const { colorMode } = useColorMode()

  return (
    <Flex
      as='a'
      lineHeight={1}
      mr={1}
      ref={ref}
      {...props}
    >
      {colorMode === 'dark' ?
        <DarkModeSVG height={props.height} />
        :
        <LightModeSVG height={props.height} />
      }
      <Text
        fontSize='8px'
        color={useAccent4()}
        letterSpacing='1px'
        borderWidth='1px'
        borderColor={useAccent2()}
        borderRadius='sm'
        mt='1px'
        py='1px'
        pl='3px'
        pr='2px'
        fontWeight={600}
        alignSelf='flex-start'
      >
        BETA
    </Text>
    </Flex>

  )
})

function HeaderContainer({ children }) {
  return (
    <Box
      borderBottomWidth='1px'
      borderBottomColor={useAccent2()}
      h='56px'
    >
      <AppContainer
        d='flex'
        py={3}
        justifyContent='space-between'
      >
        {children}
      </AppContainer>
    </Box>
  )
}

export {
  HeaderContainer,
  Logo,
  Nav,
}
