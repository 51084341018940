import { useRouter } from 'next/router'
import useFetcher from 'src/util/fetcher'
import { SWRConfig } from 'swr'
import * as Sentry from '@sentry/node'

/**
 * make sure this is a child of UserProvider,
 * because fetcher relies on the user context
 */
function SWRProvider(props) {
  const router = useRouter()
  const fetcher = useFetcher()

  return (
    <SWRConfig value={{
      fetcher,
      onError: (err, key) => {
        if (err.status !== 403 && err.status !== 404 && err.status !== 401) {
          Sentry.addBreadcrumb({
            data: {
              page: router.route,
              endpoint: key,
            },
          })
          Sentry.captureException(err)
        }
      }
    }}
      {...props}
    />
  )
}

export default SWRProvider
