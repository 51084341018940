const title = 'Projectlink – The professional network for industry leaders.'
const description = 'Projectlink connects projects and businesses. Find heavy industry projects.'

const SEO = {
  title,
  description,
  canonical: 'https://projectlink.app',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://projectlink.app',
    title,
    description,
    images: [
      // {
      //   url: 'https://projectlink.app/projectlink.png',
      //   alt: title,
      //   width: 1280,
      //   height: 720
      // }
    ]
  },
  // ...(process.env.VERCEL_ENV !== 'production' ? {
  //   dangerouslySetAllPagesToNoFollow: true,
  //   dangerouslySetAllPagesToNoIndex: true,
  // } : {})
}

export default SEO
