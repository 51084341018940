import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

const config = {
  development: {
    "apiKey": "AIzaSyD4k2nlytGqXdf2v14OjFBer07UpoWoL2Y", "authDomain": "projectlink-dev.firebaseapp.com", "databaseURL": "https://projectlink-dev.firebaseio.com", "projectId": "projectlink-dev", "storageBucket": "projectlink-dev.appspot.com", "messagingSenderId": "135047300378", "appId": "1:135047300378:web:06de8ae659635e16",
  },
  preview: {
    "apiKey": "AIzaSyD4k2nlytGqXdf2v14OjFBer07UpoWoL2Y", "authDomain": "projectlink-dev.firebaseapp.com", "databaseURL": "https://projectlink-dev.firebaseio.com", "projectId": "projectlink-dev", "storageBucket": "projectlink-dev.appspot.com", "messagingSenderId": "135047300378", "appId": "1:135047300378:web:06de8ae659635e16",
  },
  production: {
    "apiKey": "AIzaSyDQTtyS6eZizbKbDo2CmvHN3kgaH00M1us", "authDomain": "projectlink-cfdfb.firebaseapp.com", "projectId": "projectlink-cfdfb", "storageBucket": "projectlink-cfdfb.appspot.com", "messagingSenderId": "606610476317", "appId": "1:606610476317:web:a92390c318628fb68465f4", "measurementId": "G-2HCSL3BEB2",
  },
}

if (firebase?.apps.length === 0) {
  firebase?.initializeApp(config[process.env.NEXT_PUBLIC_VERCEL_ENV])
}

export default firebase
