import cookies from 'js-cookie'
import { SessionUserData } from './util'
import { authCookieKey } from 'projectlink-common'

const setCookie = (user: SessionUserData) => {
  cookies.set(authCookieKey, JSON.stringify(user), {
    expires: 90,
    sameSite: 'Lax',
    secure: process.env.NODE_ENV === 'production',
  })
}

const removeCookie = () => {
  cookies.remove(authCookieKey)
}

/**
 * @clientonly
 */
const getUserFromCookie = () => {
  const cookie = cookies.get(authCookieKey)
  if (!cookie) {
    return null
  }
  return JSON.parse(cookie) as SessionUserData
}

export {
  setCookie,
  removeCookie,
  getUserFromCookie,
}