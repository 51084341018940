const handleFetchResponse = async (r: Response) => {
  // calling .json() throws if there is not body content
  // so call .text() instead, then parse it
  const text = await r.text?.()
  const body = text ? JSON.parse(text) : null
  if (!r.ok) {
    console.debug(r.url)
    const e = new Error(body?.message || `Fetch error: ${r.url}`)
    // @ts-ignore
    e.status = r.status
    throw e
  }
  return body
}

/**
 * small wrapper around browser fetch that throws
 * for > 400 response statuses and returns request
 * body directly
 */
const _fetch = async (info: RequestInfo, init?: RequestInit) => {
  const r = await fetch(info, init)
  return handleFetchResponse(r)
}

export {
  handleFetchResponse,
  _fetch,
}
