import { Box, BoxProps } from '@chakra-ui/react'

/**
 * shorthand for layerStyle='appContainer'
 */
function AppContainer(props: BoxProps) {
	return (
		<Box
			layerStyle='appContainer'
			{...props}
		/>
	)
}

export default AppContainer;
