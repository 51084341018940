import { createContext } from 'react'
import useUser from 'src/hooks/useUser'

const context = createContext<ReturnType<typeof useUser>>({
  user: null,
  account: null,
  removeUser: () => null,
  updateUser: () => Promise.resolve(),
  refreshUser: () => Promise.resolve(),
  /**
   * merge changes to the user object locally
   * does not persist
   */
  mutateUser: _data => null,
})

function UserProvider(props) {
  const hookProperties = useUser(props.user)
  return (
    <context.Provider value={hookProperties} {...props} />
  )
}

export { context }
export default UserProvider
