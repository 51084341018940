const formatSessionUser = ({
  uid,
  email,
  token,
  claims,
  photoURL,
  displayName,
}: any): SessionUserData => ({
  uid,
  email,
  token,
  photoURL,
  claims: pluckCustomClaims(claims),
  displayName,
})

// keep the cookie lean, just store the
// claims we actually use
const pluckCustomClaims = (claims) => {
  const {
    aud,
    auth_time,
    email,
    picture,
    email_verified,
    exp,
    firebase,
    iat,
    iss,
    name,
    pictures,
    sub,
    user_id,
    ...rest
  } = claims

  return rest
}

export interface SessionUserData {
  uid: string,
  claims: { [key: string]: any },
  token: string,
  email: string,
  displayName: string,
  photoURL?: string,
}

const formatCookie = ({ claims, token, uid, displayName, email, photoURL }: SessionUserData) => ({ claims, token, uid, displayName, email, photoURL })

const hasClaim = (userClaims, claims) => {
  const test = (userClaim, claim) =>
    ((userClaim === claim) && (userClaims[userClaim] >= claims[claim]))

  const isValid = Object.keys(claims).some(claim =>
    Object.keys(userClaims).some(userClaim =>
      test(userClaim, claim)))

  return isValid
}

export {
  hasClaim,
  formatCookie,
  formatSessionUser,
}
