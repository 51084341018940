import useColorValue from "./hooks/useColorValue";

const useBackground = () => useColorValue('background')
const useAccent1 = () => useColorValue('accent1')
const useAccent2 = () => useColorValue('accent2')
const useAccent3 = () => useColorValue('accent3')
const useAccent4 = () => useColorValue('accent4')
const useAccent5 = () => useColorValue('accent5')
const useAccent6 = () => useColorValue('accent6')
const useAccent7 = () => useColorValue('accent7')
const useAccent8 = () => useColorValue('accent8')
const useForeground = () => useColorValue('foreground')

/**
 * hooks that return a color value!
 */
export {
  useBackground,
  useAccent1,
  useAccent2,
  useAccent3,
  useAccent4,
  useAccent5,
  useAccent6,
  useAccent7,
  useAccent8,
  useForeground,
}
