import Link from "next/link"
import { Button } from "@chakra-ui/button"
import { Box, Flex, Stack } from "@chakra-ui/layout"
import { useEffect, useRef, useState } from "react"
import accountService from "src/access/account"
import useUserContext from "src/hooks/useUserContext"
import { routes } from "src/navigation/util"
import { A, Caption, P } from "src/typography/typography"
import { windowContext } from "src/util"
import { useAccent2, useBackground } from "src/modeAwareColors"
import { useLightModeValue } from "src/util/theme"

const CURRENT_COOKIE_VERSION = 1

function CookieConsent() {
  const { account } = useUserContext()
  const [showNotice, setShowNotice] = useState(false)
  const firstRender = useRef(true)
  const shadow = useLightModeValue('lg')
  const bg = useBackground()
  const a2 = useAccent2()

  const onClick = async () => {
    setShowNotice(false)
    window.localStorage.setItem('pl-cookie-consent', JSON.stringify(CURRENT_COOKIE_VERSION))
    if (account) {
      await accountService.update(account.id, {
        cookieConsentVersion: CURRENT_COOKIE_VERSION,
      })
    }
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    let consentedVersion

    const updateAccount = async v => account && await accountService.update(account.id, { cookieConsentVersion: v })

    if (windowContext()) {
      consentedVersion = parseInt(window.localStorage.getItem('pl-cookie-consent') || '0')
    }

    if (!consentedVersion && account) {
      consentedVersion = account?.cookieConsentVersion
    }

    if (consentedVersion > (account?.cookieConsentVersion || 0)) {
      updateAccount(consentedVersion)
    }

    const hasConsent = consentedVersion >= CURRENT_COOKIE_VERSION
    setShowNotice(!hasConsent)
  }, [account])

  if (!showNotice) {
    return null
  }

  return (
    <Box
      bottom={4}
      right={4}
      bg={bg}
      position='fixed'
      borderRadius='lg'
      borderColor={a2}
      borderWidth='1px'
      boxShadow={shadow}
      maxW='container.lg'
      p={6}
    >
      <Flex
        align='center'
      >
        <Box flexGrow={1} mr='base'>
          <P>
            Cookie notice
          </P>
          <Caption>
            We use cookies to enhance Projectlink.
          </Caption>
        </Box>
        <Stack>
          <Button
            onClick={onClick}
          >
            Accept
          </Button>
          <Link
            href={routes.cookies}
            passHref
          >
            <A
              fontSize='sm'>
              Cookie policy
          </A>
          </Link>
        </Stack>
      </Flex>
    </Box>
  )
}

export default CookieConsent
