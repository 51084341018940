import { Settings } from 'projectlink-common'
import { SessionUserData } from 'src/auth/util'
import { _fetch } from 'src/util/access'
import firebase from '../util/firebaseWeb'
import accountService from './account'
import endpoints from './endpoints'
import settings from './settings'
import userService from './user'

const DEFAULT_SETTINGS: Settings = {
  alerts: {
    shownInterest: true,
    newProjects: true,
    digest: true,
  },
}

const emailSignin = (email, displayName?) => {
  return _fetch(endpoints.signin(), {
    method: 'post',
    body: JSON.stringify({ email, displayName }),
  })
}

const createDocuments = async (user: SessionUserData) => {
  const domainRef = {
    id: user.uid,
    displayName: user.displayName,
    photoUrl: user.photoURL,
    email: user.email,
  }

  return Promise.all([
    userService.create(domainRef),
    accountService.create(user.uid),
    settings.create(user.uid, DEFAULT_SETTINGS),
  ])
}

const logout = () => {
  return firebase.auth().signOut()
}

export {
  emailSignin,
  logout,
  createDocuments,
}
