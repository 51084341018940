import useUserContext from 'src/hooks/useUserContext'

/**
 * this custom fetcher ensures auth ID token
 * is not expired and gets a new token if it is.
 * this should happen on each request.
 */
const useFetcher = () => {
  const { updateUser } = useUserContext()

  return async url => {
    // refresh ID token if its expired
    await updateUser()
    const r = await fetch(url)

    if (!r.ok) {
      if (r.status === 401) {
        // force a token refresh
        await updateUser(null, true)
      }
      let msg = (await r.json()).message || 'SWR fetcher error'
      const error = new Error(msg)
      // @ts-ignore error doesnt have a status? it does now bitch
      error.status = r.status
      throw error
    } else {
      return r.json()
    }
  }
}

export default useFetcher
