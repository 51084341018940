import * as React from "react"

function Logo(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 39 32"
      {...props}
    >
      <path
        d="M16.023 25.895a8.634 8.634 0 01-5.524-.994 8.646 8.646 0 01-4.147-5.213 8.646 8.646 0 01.754-6.618 8.636 8.636 0 015.213-4.147c.004 0 .008-.002.011-.003.344-.098.69-.175 1.038-.23a8.608 8.608 0 015.362.876 8.646 8.646 0 014.354 5.324 8.647 8.647 0 01-.871 6.823 7.072 7.072 0 003.636-.228 11.805 11.805 0 00.302-7.473 11.803 11.803 0 00-5.062-6.765 12.16 12.16 0 00-.894-.514 11.861 11.861 0 00-5.183-1.338 12.01 12.01 0 00-3.572.462 11.814 11.814 0 00-7.124 5.667 11.815 11.815 0 00-1.03 9.044 11.815 11.815 0 005.666 7.123 11.815 11.815 0 009.044 1.03 12.008 12.008 0 002.27-.908 13.581 13.581 0 01-4.243-1.918z"
        fill="url(#prefix__projectlink-logo__paint0_radial)"
      />
      <path
        d="M29.366 4.309a11.814 11.814 0 00-9.044-1.03c-.794.227-1.553.533-2.269.908a13.578 13.578 0 014.243 1.918 8.634 8.634 0 015.524.994 8.646 8.646 0 014.146 5.213 8.647 8.647 0 01-.754 6.618A8.636 8.636 0 0126 23.077c-.004 0-.008.002-.011.003-.344.098-.69.175-1.038.23a8.607 8.607 0 01-5.361-.876 8.646 8.646 0 01-4.354-5.325 8.647 8.647 0 01.87-6.822 7.072 7.072 0 00-3.635.227 11.804 11.804 0 00-.302 7.474 11.803 11.803 0 005.061 6.765 12.196 12.196 0 00.894.514 11.863 11.863 0 005.183 1.338 12.008 12.008 0 003.573-.462 11.814 11.814 0 007.123-5.667 11.815 11.815 0 001.03-9.044 11.82 11.82 0 00-5.667-7.123z"
        fill="url(#prefix__projectlink-logo__paint1_radial)"
      />
      <defs>
        <radialGradient
          id="prefix__projectlink-logo__paint0_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-126.87 16.75 7.25) scale(20 19.0409)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.511} />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="prefix__projectlink-logo__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(15 14.00002 -14.63028 15.67528 17.5 7.5)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.476} />
          <stop offset={1} />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default Logo
