export default {
  size: {
    default: {
      lineHeight: 1.6,
      fontWeight: 600,
    },
  },
  defaultProps: {
    size: 'default',
  },
}