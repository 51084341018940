import {
  Box,
  Text,
  FormLabel as ChakraFormLabel,
  BoxProps,
  TextProps,
  Heading,
} from '@chakra-ui/react';
import Link from 'next/link';
import { FC, forwardRef } from 'react';
import useColorValue from 'src/hooks/useColorValue';
import { useAccent4, useForeground } from 'src/modeAwareColors';
import { withInactive } from './util';

const Eyebrow = withInactive((props: BoxProps) => (
  <Box as='h4'
    textStyle='eyebrow'
    color='primary.accent5'
    {...props} />
))

/**
 * shorthand for <Text as='p' textStyle='p' />
 */
const P = withInactive((props) => (
  <Text as='p'
    textStyle='p'
    {...props}
  />
))

const Detail = forwardRef<any, any>((props, ref) => {
  const accent6 = useColorValue('accent6')
  return (
    <Text
      ref={ref}
      fontSize='14px'
      fontWeight={500}
      color={accent6}
      {...props}
    />
  )
})

const A = forwardRef<any, any>((props: TextProps, ref) => (
  <Text as='a'
    fontWeight='normal'
    fontSize='md'
    cursor='pointer'
    color={useAccent4()}
    transition='color .1333s'
    tabIndex={0}
    _hover={{
      transition: 'color .28s',
      color: useForeground(),
    }}
    ref={ref}
    {...props}
  />
))

const Hyperlink = forwardRef<any, any>((props: TextProps, ref) => (
  <A
    color='success.default'
    _hover={{
      color: 'success.dark',
      textDecoration:'underline',
    }}
    ref={ref}
    {...props}
  />
))

const NextHyperlink: FC<BoxProps & { href: string }> = ({ href, ...rest }) => (
  <Link href={href} passHref><Hyperlink {...rest} /></Link>
)

const BoldLink = forwardRef<any, any>((props: TextProps, ref) => (
  <Text as='a'
    fontWeight={500}
    color={useForeground()}
    cursor='pointer'
    _hover={{
      textDecoration: 'underline',
      color: useForeground(),
    }}
    ref={ref}
    {...props}
  />
))

const NextBoldLink: FC<BoxProps & { href: string }> = ({ href, ...rest }) => (
  <Link href={href} passHref><BoldLink {...rest} /></Link>
)

function Caption({ children, ...rest }) {
  return (
    <Text as='p'
      color={useAccent4()}
      fontWeight='normal'
      fontSize='sm'
      {...rest}
    >
      {children}
    </Text>
  );
}

const FormLabel = ({ children, ...rest }) =>
  <ChakraFormLabel
    textStyle='label'
    {...rest}
  >
    {children}
  </ChakraFormLabel>

const Tag = (props) => (
  <Text as='p'
    px='2px'
    py={0}
    color='teal.800'
    fontSize='xs'
    borderWidth='1px'
    borderRadius='md'
    borderColor='teal.800'
    {...props}
  />
)

const FinePrint = ({ link = false, ...rest }) => (
  <Text
    textStyle='finePrint'
    cursor={link && 'pointer'}
    _hover={link && { color: 'primary.foreground' }}
    {...rest}
  />
)

const Error = props => <Text textStyle='p' color='error.default' {...props} />

const H1 = props => {
  const color = useForeground()
  return (
    <Heading
      as='h1'
      textStyle='h1'
      color={color}
      {...props}
    />
  )
}

const H2 = props => {
  const color = useForeground()
  return (
    <Heading
      as='h2'
      textStyle='h2'
      color={color}
      {...props}
    />
  )
}

const H3 = props => {
  const color = useForeground()
  return (
    <Heading
      as='h3'
      textStyle='h3'
      color={color}
      {...props}
    />
  )
}

const H4 = props => {
  const color = useForeground()
  return (
    <Heading
      as='h4'
      textStyle='h4'
      color={color}
      {...props}
    />
  )
}

const H5 = props => {
  const color = useForeground()
  return (
    <Heading
      as='h5'
      textStyle='h5'
      color={color}
      {...props}
    />
  )
}

export {
  P,
  A,
  Hyperlink,
  NextHyperlink,
  BoldLink,
  NextBoldLink,
  Tag,
  FormLabel,
  Caption,
  Eyebrow,
  FinePrint,
  Error,
  Detail,
  H1,
  H2,
  H3,
  H4,
  H5,
}
