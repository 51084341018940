import { DomainObjects } from 'projectlink-common'

/**
 * one source to rule them all
 */
const routes = {
  confirmEmail: '/confirm-email',
  emailConfirmed: '/email-confirmed',
  designSystem: '/design-system',
  home: '/',
  projectOwners: '/project-owners',
  suppliers: '/suppliers',
  login: '/login',
  signup: '/signup',
  account: '/account',
  search: '/search',
  newOrganization: '/new-organization',
  organizationRequest: '/organization-request',
  newCaseStudy: '/new-case-study',
  newReference: '/new-reference',
  newProject: '/new-project',
  privacy: '/privacy',
  terms: '/terms',
  cookies: '/cookies',
  gate: '/gate',
  companies: '/companies',
  projects: '/projects',
  joinOrg: '/join-organization',
  onboarding: '/onboarding',
  topics: '/topics',
  countries: '/countries',
  authenticate: '/authenticate',
  howItWorks: '/how-it-works',
  contact: '/contact',
  explore: '/explore',
  pricing: '/pricing',
  payment: '/payment',
  dashboard: '/dashboard',
  claim: id => `/claim/${id}`,
  topic: t => `/topic/${t}`,
  country: c => `/country/${c}`,
  [DomainObjects.user]: id => `/${DomainObjects.user}/${id}`,
  [DomainObjects.organization]: id => `/${DomainObjects.organization}/${id}`,
  [DomainObjects.project]: id => `/${DomainObjects.project}/${id}`,
  [DomainObjects.caseStudy]: id => `/${DomainObjects.caseStudy}/${id}`,
}

export {
  routes,
}
