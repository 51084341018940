import { AccountDocument, Consumable } from 'projectlink-common'
import { _fetch } from 'src/util/access'
import { mutate } from 'swr'
import endpoints from './endpoints'

const create = (id: string) => {
  return fetch(endpoints.accounts(id), {
    method: 'post',
  })
}

const update = async (id: string, data: Partial<AccountDocument>) => {
  mutate(endpoints.accounts(id), async entity => ({ ...entity, ...data }), true)

  return fetch(endpoints.accounts(id), {
    method: 'put',
    body: JSON.stringify(data),
  })
}

const updateConsumable = async (accountId: string, consumableId: string, data: Partial<Consumable>) => {
  return _fetch(`${endpoints.accounts(accountId)}/consumables/${consumableId}`, {
    method: 'put',
    body: JSON.stringify(data),
  })
}

/**
 * @clientonly
 */
const accountService = {
  create,
  update,
  updateConsumable,
}

export default accountService