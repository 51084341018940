import { FirestoreCollections, AccountDocument } from 'projectlink-common';
import { useEffect, useState } from 'react';
import { getUserFromCookie, removeCookie, setCookie } from 'src/auth/userCookies';
import { formatCookie, formatSessionUser, SessionUserData } from 'src/auth/util';
import firebase from 'src/util/firebaseWeb';
import 'firebase/firestore'
import * as Sentry from '@sentry/browser'
import { windowContext } from 'src/util';
import logError from 'src/access/errorLogger';
import analytics from 'src/util/analytics';
import userService from 'src/access/user';
import { createDocuments } from 'src/access/auth';

/**
 * utilities for managing current user
 */
function useUser(initialState?: SessionUserData) {
  let accountListener

  const [user, setUser] = useState<SessionUserData>(initialState || getUserFromCookie())
  const [account, setAccount] = useState<AccountDocument>(null)

  // useEffect(() => {
  //   if (!account) return
  //   if (!account?.activeOrg?.id) {
  //     const init = async () => {
  //       await showcaseService.add(account.id, {
  //         colorScheme: 'success',
  //         title: 'Join your company',
  //         body: 'Join or add your company to post a project',
  //         links: [{ title: 'Join company', href: routes.joinOrg }],
  //       })
  //     }

  //     init()
  //   }
  // }, [account])

  useEffect(() => {
    if (!windowContext()) return // dont do any of this work if server rendering

    if (!user?.uid) return () => {
      const unsub = accountListener
      if (unsub) unsub()
    }

    const { token, ...rest } = user

    Sentry.addBreadcrumb({
      data: {
        user: rest,
      },
    })

    // @ts-ignore
    // heap.identify(user.uid)
    // @ts-ignore
    // heap.addUserProperties({
    //   displayName: user.displayName,
    //   email: user.email,
    // })

    analytics.setUserId(user.uid)
    analytics.setUserProperties({ email: user.email, displayName: user.displayName })

    accountListener = firebase
      .firestore()
      .collection(FirestoreCollections.accounts)
      .doc(user.uid)
      .onSnapshot(snapshot => {
        if (!snapshot.exists) {
          return createDocuments(user) // every user should have an account here, but just incase
        }

        setAccount(snapshot.data?.() as AccountDocument)

        if (snapshot.data()?.hasUpdate) {
          refreshUser() // account updates are used to signal there's changes to auth object
          firebase
            .firestore()
            .collection(FirestoreCollections.accounts)
            .doc(user.uid)
            .update({ hasUpdate: false })
        }
      }, () => updateUser())

    /**
     * this effect sometimes runs before the cookie is set
     * protected routes need the cookie to authenticate
     */
    if (user.uid && getUserFromCookie()) {
      userService.onAuthenticate(user.uid)
    }

    return () => {
      const unsub = accountListener
      if (unsub) unsub()
    }
  }, [user?.uid])

  const updateUser = async (user?: firebase.User, forceRefresh = false) => {
    let _user = user || firebase.auth().currentUser

    if (!_user) {
      return
    }

    try {
      const [{ claims }, token] = await Promise.all([
        _user.getIdTokenResult(),
        _user.getIdToken(forceRefresh),
      ])
      const sessionUserData = formatSessionUser({ ..._user, claims, token });
      setUser(sessionUserData)
      setCookie(formatCookie(sessionUserData)) // cookie used by servers to authenticate requests
      // setIsNewSignIn(isNewSignIn)
    } catch (e) {
      console.warn('[updateUser]')
      logError(e)
    }
  }

  /**
   * call when there's a change to user details
   */
  const refreshUser = async () => {
    await firebase.auth().currentUser?.reload()
    const user = firebase.auth().currentUser
    await updateUser(user, true) // refresh id token incase there are changes to custom claims
  }

  const removeUser = () => {
    setUser(null)
    removeCookie()
  }

  /**
   * mutate user data locally. does not persist and
   * will be overwritten on next auth update fetch
   */
  const mutateUser = (data: Partial<SessionUserData>) => {
    const update = { ...user, ...data }
    setUser(update)
    setCookie(update)
  }

  return {
    user,
    account,
    mutateUser,
    removeUser,
    updateUser,
    refreshUser,
    // accountError: error,
  }
}

export default useUser
