import { auth } from 'firebase-admin'
import { AccessLevel, User } from 'projectlink-common'
import { _fetch } from 'src/util/access'
import endpoints from './endpoints'

const getByEmail = async (email: string): Promise<auth.UserRecord> => {
  const body = JSON.stringify({ query: { email } })

  const r = await fetch(`${endpoints.users()}`, {
    method: 'post',
    body,
  })

  // we actually just want to return falsey cause the user might not exist
  // and in that case throwing isnt appropriate
  if (!r.ok) return null

  return r.json()
}

const setClaims = async (userId: string, claims: { [key: string]: AccessLevel }) => {
  const r = await fetch(`${endpoints.users(userId)}/claims`, {
    method: 'put',
    body: JSON.stringify(claims),
  })

  if (!r.ok) throw new Error((await r.json()))
  return r
}

const create = async (user: Omit<User, 'type'>) => {
  const r = await fetch(`${endpoints.users(user.id)}`, {
    method: 'post',
    body: JSON.stringify(user),
  })

  if (!r.ok) throw new Error((await r.json()))
  return r
}

/**
 * even though we actually use firebase user profile,
 * we still keep a firestore document syned with this
 * in order to use cloud functions to keep roles and
 * contacts synced up with profile changes.
 */
const update = async (userId: string, data: Partial<User>) => {
  const r = await fetch(`${endpoints.users(userId)}`, {
    method: 'put',
    body: JSON.stringify(data),
  })

  if (!r.ok) throw new Error((await r.json()))
  return r
}

const onAuthenticate = (userId) => {
  return _fetch(`${endpoints.users(userId)}/authenticate`, { method: 'post' })
}

const userService = {
  create,
  update,
  getByEmail,
  setClaims,
  onAuthenticate,
}

export default userService
