import { useEffect, useState } from 'react'
import useUserContext from './useUserContext'

function useIsSignedIn() {
  const { user } = useUserContext()
  /**
   * defaulting to true optimizes the experience
   * for signed in users
   */
  const [isSignedIn, setIsSignedIn] = useState(true)

  useEffect(() => {
    setIsSignedIn(Boolean(user?.uid))
  }, [user])

  return isSignedIn
}

export default useIsSignedIn
