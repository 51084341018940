import { Box, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import Link from 'next/link';
import { forwardRef } from 'react';
import { Logo } from 'src/layout/shared';
import { useAccent2, useForeground } from 'src/modeAwareColors';

function FooterHeading(props) {
  return (
    <Heading
      as='h4'
      fontSize='xs'
      color='primary.accent3'
      textTransform='uppercase'
      {...props}
    />
  )
}

const FooterLinkText = forwardRef<HTMLAnchorElement, any>((props, ref) => {
  return (
    <Text
      as='a'
      ref={ref}
      fontSize='xs'
      color='primary.accent3'
      cursor='pointer'
      _hover={{
        color: 'primary.accent5'
      }}
      {...props}
    />
  )
})

function FooterLink({ children, href }) {
  return (
    <Link
      href={href}
      passHref
    >
      <FooterLinkText>
        {children}
      </FooterLinkText>
    </Link>
  )
}

function Footer() {
  const bg = useColorModeValue('primary.accent1', 'dark.background')
  const copyrightColor = useColorModeValue('primary.accent3', 'dark.accent2')

  return (
    <Box
      borderTopWidth='1px'
      borderTopColor={useAccent2()}
      bg={bg}
    >
      <Stack
        layerStyle='appContainer'
        direction='row'
        py={24}
      >
        <Stack
          w={['50%', '50%', '25%']}
          spacing='sm'
        >
          <FooterHeading>
            About
          </FooterHeading>
          <FooterLink
            href='/how-it-works'
          >
            How does Projectlink work?
          </FooterLink>
          <FooterLink
            href='/investors'
          >
            Investors
          </FooterLink>
        </Stack>
        <Stack
          w={['50%', '50%', '25%']}
          spacing='sm'
        >
          <FooterHeading>
            Legal
          </FooterHeading>
          <FooterLink
            href='/terms'
          >
            Terms of service
          </FooterLink>
          <FooterLink
            href='/privacy'
          >
            Privacy
          </FooterLink>
          <FooterLink
            href='/cookies'
          >
            Cookies
          </FooterLink>
        </Stack>
        <Stack
          w={['50%', '50%', '25%']}
          spacing='sm'
        >
          {/* <Flex> */}
          <Logo />
          <Heading
            as='h2'
            fontFamily='brand'
            fontSize='lg'
            fontWeight={400}
            color={useForeground()}
          >
            Projectlink
          </Heading>
          <Text
            fontSize='xs'
            color={copyrightColor}
          >
            © Copyright Projectlink {(new Date).getFullYear()}
          </Text>
          {/* </Flex> */}
          {/* <FormControl display="flex" alignItems="center">
            <FooterLinkText
              as='label'
              htmlFor="dark-mode"
              mr={2}
            >
              Dark mode
            </FooterLinkText>
            <Switch
              id="dark-mode"
              size='sm'
              onChange={toggleColorMode}
              defaultChecked={colorMode === 'dark'}
            />
          </FormControl> */}
        </Stack>
        {/* <Button onClick={toggleColorMode}>
          Color mode
        </Button> */}
      </Stack>
    </Box>
  )
}

export default Footer
