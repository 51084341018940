import { createContext, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import { chakra } from "@chakra-ui/system"
import { Box, Flex } from "@chakra-ui/layout"
import Icon from '@chakra-ui/icon'
import { FiX } from 'react-icons/fi'
import AppContainer from 'src/common/AppContainer'
import { NextBoldLink, P } from 'src/typography/typography'
import useUserContext from 'src/hooks/useUserContext'
import { routes } from "src/navigation/util"
import endpoints from 'src/access/endpoints'
import accountService from 'src/access/account'
import { Slide } from '@chakra-ui/transition'
import { useDisclosure } from '@chakra-ui/hooks'

const alertBannerContext = createContext({
  isOpen: false,
  onToggle: () => null,
  close: () => null,
})

const useAlertBannerContext = () => useContext(alertBannerContext)

function AlertBannerProvider(props) {
  const { isOpen, onToggle } = useDisclosure()
  const close = () => {
    isOpen ? onToggle() : null
  }

  return (
    <alertBannerContext.Provider
      value={{ isOpen, onToggle, close }}
      {...props}
    />
  )
}

function ConsumableContainer({ colorScheme, children, onClose }) {
  return (
    <Box
      bg={`${colorScheme}.lighter`}
      borderColor={`${colorScheme}.light`}
      borderTopWidth='1px'
      borderBottomWidth='1px'
    >
      <AppContainer
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <P>
          {children}
        </P>
        <Flex
          p={1}
          cursor='pointer'
          borderRadius='sm'
          onClick={onClose}
          transition='.3s all'
          _hover={{
            transition: '.15s all',
            bg: 'rgba(0,0,0,0.05)',
          }}
        >
          <Icon as={FiX} color={`${colorScheme}.dark`} />
        </Flex>
      </AppContainer>
    </Box>
  )
}

/**
 * listens to changes to a users account document
 * and displays alert messages
 *
 * displays the most recent consumable with charges > 0
 */
function AlertBanner() {
  const { user } = useUserContext()
  const { data: consumables } = useSWR(user?.uid && `${endpoints.accounts(user.uid)}/consumables`)
  const [activeConsumable, setActiveConsumable] = useState(null)
  const { isOpen, onToggle, close } = useContext(alertBannerContext)

  useEffect(() => {
    if (consumables) {
      const mostRecentWithCharges = consumables
        .sort((a, b) => a.createdAt - b.createdAt)
        .find(c => c.charges > 0)

      if (mostRecentWithCharges) {
        setActiveConsumable(mostRecentWithCharges)
        if (!isOpen) {
          onToggle()
        }
      }
    }
  }, [consumables])

  const onClose = async () => {
    setActiveConsumable(null)
    close()
    await accountService.updateConsumable(user.uid, activeConsumable.id, {
      charges: activeConsumable.charges - 1,
    })
  }

  const colorScheme = activeConsumable?.colorScheme || 'error'

  return (
    <Slide direction='bottom' in={isOpen}>
      <ConsumableContainer colorScheme={colorScheme} onClose={onClose}>
        <chakra.span color={`${colorScheme}.dark`}>
          Your payment has failed. Please <NextBoldLink
            color={`${colorScheme}.dark`}
            _hover={{
              color: `${colorScheme}.dark`,
              textDecoration: 'underline',
            }}
            href={`${routes.account}?tab=1&setting=2&new-payment-method=true`}>update your payment method</NextBoldLink>.
        </chakra.span>
      </ConsumableContainer>
    </Slide>
  )
}

export {
  AlertBannerProvider,
  useAlertBannerContext,
}
export default AlertBanner
