import Link from 'next/link';
import { HeaderContainer, Logo, Nav } from './shared'
import Footer from '../common/Footer'
import { useRouter } from 'next/router'
import { routes } from '../navigation/util'
import { Box, Button, Flex, useColorModeValue } from '@chakra-ui/react'
import AlertBanner from 'src/alertBanner'
import { P } from 'src/typography/typography'

const CalloutText = props =>
  <P textAlign='center'
    py={2}
    fontSize='sm'
    {...props} />

function Callout() {
  const fg = useColorModeValue('primary.background', 'primary.foreground')
  const bg = useColorModeValue('primary.foreground', 'primary.background')

  return (
    <>
      <Box height='37px' />
      <Box
        bg={bg}
        w='100%'
        d='flex'
        zIndex={1}
        position='fixed'
        justifyContent='center'
        alignItems='center'>
        <CalloutText color={fg}>We're industry experts. &nbsp;</CalloutText>
        <Link href={routes.contact} passHref>
          <Button size='xs' variant='negative'>
            Book a call →
          </Button>
        </Link>
      </Box>
    </>
  )
}

function MainLayout({ children }) {
  const router = useRouter()
  const bg = useColorModeValue('primary.background', 'dark.background')

  // useful for omitting elements from certain routes 
  const renderHeader = () => {
    switch (router.route) {
      case routes.login:
      case routes.payment:
      case routes.signup: return (<Logo />);
      default: return (
        <HeaderContainer>
          <Logo />
          <Nav />
        </HeaderContainer>
      );
    }
  }

  // useful for omitting elements from certain routes 
  const renderCallout = () => {
    switch (router.route) {
      case routes.home:
      case routes.projectOwners:
      case routes.suppliers: return (<Callout />);
      default: return null
    }
  }

  return (
    <Flex
      bg={bg}
      direction='column'
      minH='100vh'
    >
      {renderCallout()}
      {renderHeader()}
      <Box flex='1 0 auto'>
        {children}
      </Box>
      <AlertBanner />
      <Footer />
    </Flex>
  )
}

export default MainLayout
